import React from 'react';
import { DataProvider } from '@plasmicapp/host';
import { observer } from 'mobx-react-lite';
import { usePrizeStore } from 'hooks/stores/usePrizeStore';

// Users will be able to set these props in Studio.
interface PrizeGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const PrizeGlobalContext = observer(
  ({ children }: React.PropsWithChildren<PrizeGlobalContextProps>) => {
    const { trendingPrizes } = usePrizeStore();

    return (
      <DataProvider
        name="prizes"
        data={{
          trendingPrizes,
        }}
      >
        {children}
      </DataProvider>
    );
  }
);
